import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const Gallery = ({data}: {data: any}) => {
    return (
      <div className="max-w-6xl mx-auto p-5">
          <div className="grid md:grid-cols-3 gap-4">
              {data.allFile.edges.map((edge: any, i: string) => {
                  return <GatsbyImage alt="" image={edge.node.childImageSharp.gatsbyImageData} key={i} />;
              })}
          </div>
          <p className="py-10">(c) {new Date().getFullYear()} Munib Rahman. All rights reserved.</p>
      </div>
    );
}

export default Gallery

export const indexQuery = graphql`query AssetsPhotos {
  allFile(
    filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|(webp)/"}, dir: {regex: "/images/gram/"}}
    sort: {order: DESC, fields: changeTime}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(width: 300, layout: FIXED)
        }
      }
    }
  }
}

`